<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h2>Master Router</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                v-for="item in items"
                :key="item"
                xs="12"
                sm="12"
                md="6"
                lg="4"
                xl="3">
                <router-link :to="route(item)">
                    <v-btn style="margin-bottom: 15px">
                        {{ item }}
                    </v-btn>
                </router-link>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from "axios";

export default {
    components: {},

    data() {
        return {
            items: [],
        };
    },

    async mounted() {
        let response = await axios.get("/api/MasterRouter/GetRoutes");
        this.items = response.data;
    },

    methods: {
        getName(item) {
            return item;
        },
        route(item) {
            let path = item
                .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
                .map(x => x.toLowerCase())
                .join("-");
            let options = { path };
            return options;
        },
    },
};
</script>
